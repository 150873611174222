import { graphql } from "gatsby";
import * as React from "react";
import Layout from "~/components/Layout";

import FlexibleContent from "~/components/FlexibleContent";
import { WpPage_Page_FlexibleContent } from "~/_generated/types";

export default function fourOhFourPage({
  data: { wpPage, wp },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  return (
    <Layout wp={wp} hideFooter>
      <FlexibleContent content={wpPage?.page?.flexibleContent} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query FourOhFour {
    wpPage(id: {eq: "cG9zdDo2Mjc="}) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
